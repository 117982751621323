<template>
  <div class="list-brands">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách thương hiệu'">
          <template v-slot:toolbar>
            <div class="row">
              <button
                type="button"
                v-if="checkPermission('BRAND_INSERT')"
                class="btn btn-primary font-weight-bolder btn-sm"
                @click="addRow"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
              </button>
            </div>
          </template>
          <template v-slot:preview>
            <div class="col-md-12 mb-5">
              <b-form>
                <div class="row">
                  <b-input
                    size="sm"
                    placeholder="Tìm kiếm"
                    v-model.trim="search"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-3"
                    @input="onInputChange"
                    v-on:keyup.enter="fetchBrand"
                  ></b-input>
                  <div class="col-md-3" style="padding: 0">
                    <div class="d-flex justify-content-end"></div>
                  </div>
                </div>
              </b-form>
            </div>

            <table
              class="table table-bordered table-vertical-center table-hover col-md-6"
            >
              <thead>
                <tr>
                  <th
                    style="
                      textalign: center;
                      color: rgb(24, 28, 50);
                      width: 12%;
                    "
                  >
                    STT
                  </th>
                  <!-- <th>Mã thương hiệu</th> -->
                  <th
                    style="textalign: center; color: rgb(24, 28, 50)"
                    scope="col"
                  >
                    Tên thương hiệu
                  </th>
                  <th style="width: 20%" scope="col"></th>
                </tr>
              </thead>

              <tbody v-for="item in brands" :key="item.id">
                <BrandItem
                  v-bind:item="item"
                  v-on:updateItem="updateBrand"
                  v-on:deleteItem="deleteBrand"
                  v-on:addItem="addNewBrand"
                  v-on:cancelAdd="cancelAdd"
                  :parentEdit="parentEdit"
                />
              </tbody>
            </table>

            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số thương hiệu:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col style="padding-left: 2rem">
                <b-pagination-nav
                  v-show="totalPages >= 2"
                  class="custom-pagination"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchBrand"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1"
                  last-class="page-item-last btn btn-icon btn-sm m-1"
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import SavaDataUtil from './../../../utils/saveDataToLocal';
import ApiService from '@/core/services/api.service';
import BrandItem from './BrandItem';
import { v4 as uuidv4 } from 'uuid';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  data() {
    return {
      parentEdit: false,
      item: {
        name,
      },
      edit: false,
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      search: '',
      brands: [],
      itemChoice: null,
      listFeature: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
    };
  },
  components: {
    KTCodePreview,
    BrandItem,
  },
  created() {
    this.getListFeature();
    this.fetchBrand();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thương hiệu', route: '/brands' },
      { title: 'Danh sách thương hiệu' },
    ]);
  },
  methods: {
    getListFeature() {
      this.listFeature = SavaDataUtil.getData('listFeature');
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    addRow() {
      this.edit = true;
      this.parentEdit = this.edit;
      this.$emit('parentEdit', this.parentEdit);
      let data = {
        count: '',
        id: '',
        name: '',
      };
      if (this.search){ 
        return;
      }
      this.brands.unshift(data);
    },
    cancelAdd() {
      this.brands.shift();
    },
    fetchBrand() {
      this.page = this.$route.query.page;

      if (!this.page) {
        this.page = 1;
      }

      if (!this.search) {
        this.search = '';
      }
      if (this.search) {
        this.page = 1;
      }
      let param = {
        page: this.page,
        pageSize: 10,
        name: this.search || '',
      };

      let paramQuery = {
        params: {
          param,
        },
      };
      ApiService.setHeader();
      ApiService.query('brands', paramQuery).then((response) => {
        this.brands = [];
        this.totalPages = response.data.totalPages;
        this.totalItems = response.data.totalItems;
        this.currentPage = response.data.currentPage;
        response.data.brands.forEach((item, index) => {
          let brand = {
            count:
              this.currentPage >= 2
                ? index + 1 + this.currentPage * 10 - 10
                : index + 1,
            id: item.id,
            name: item.name,
          };
          this.brands.push(brand);
          this.parentEdit = false;
        });
      });
    },
    addNewBrand(item) {
      if (item.name === '') {
        return;
      }
      let paramQuery = {
        params: { id: uuidv4(), name: item.name },
      };
      ApiService.setHeader();
      ApiService.post('brands', paramQuery).then((response) => {
        const { status, message } = response.data;
        this.fetchBrand();
        if (status === 1) {
          this.makeToastSuccess(message);
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    updateBrand(item) {
      if (item.name === '') {
        return;
      }
      let paramQuery = {
        params: { name: item.name },
      };
      ApiService.setHeader();
      ApiService.post('brands' + '/' + item.id, paramQuery).then((response) => {
        this.fetchBrand();
        const { status, message } = response.data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    deleteBrand(id) {
      ApiService.setHeader();
      ApiService.patch('brands' + '/' + id).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchBrand();
          this.makeToastSuccess(message);
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    debounceInput: decounce(function () {
      this.fetchBrand();
    }, TIME_TRIGGER),
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.search = text.trim();
      this.debounceInput();
    },
  },
};
</script>

<style lang="scss">
.list-brands {
  .icon:hover {
    background-color: #90c6fc;
  }

  table {
    table-layout: fixed;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
