<template>
  <tr class="heighRow">
    <td style="width: 10%">
      <div class="d-flex justify-content-center">
        <span v-text="item.count" class="text-right"></span>
      </div>
    </td>
    <td class="textWidth" style="padding: 0 20px 0 20px">
      <div v-if="edit">
        <input
          v-model.trim="itemChoice.name"
          placeholder="Tên thương hiệu"
          class="w-100 h-90 input form-control form-control-sm"
          oninvalid="alert('Nhập đầy đủ thông tin!');"
          style="border-color: #97c7f9"
        />
        <p style="margin: 0; color: red" v-if="error">
          Hãy nhập tên thương hiệu
        </p>
      </div>
      <span class="font-weight-bolder" v-else>{{ item.name }}</span>
    </td>

    <td>
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon
            class="mr-2 text-primary icon"
            style="font-size: 18px"
            @click="checkvalidation()"
            >mdi-check</v-icon
          >
          <v-icon
            class="text-danger icon"
            style="font-size: 18px"
            @click="cancelEdit()"
            >mdi-close</v-icon
          >
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <b-dropdown size="sm" id="dropdown-left" no-caret right>
            <template slot="button-content">
              <i
                style="font-size: 1rem; padding-right: 0px"
                class="flaticon2-settings"
              ></i>
            </template>
            <b-dropdown-item
              @click="editItem()"
              v-show="checkPermission('BRAND_UPDATE')"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="flaticon2-pen"></i>
                &nbsp; Chỉnh sửa
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="showDeleteAlert"
              v-show="checkPermission('BRAND_DELETE')"
            >
              <span style="color: #3f4254; font-size: 12px">
                <i
                  style="font-size: 1rem; color: #d33"
                  class="flaticon2-rubbish-bin-delete-button"
                ></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </td>
  </tr>
</template>

<style scoped>
.heighRow {
  height: 40px;
}
.textWidth {
  width: 70%;
}
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}

.v-icon:hover {
  background-color: #90c6fc;
}

table {
  table-layout: fixed;
}
</style>

<script>
import Swal from 'sweetalert2';
import localData from '../../../utils/saveDataToLocal';

export default {
  props: ['item', 'listFeature', 'parentEdit'],
  data() {
    return {
      edit: false,
      itemChoice: {
        id: '',
        name: '',
      },
      error: false,
    };
  },
  updated() {
    this.checkAddRow();
  },
  beforeMount() {
    this.edit = this.parentEdit;
  },
  methods: {
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.item.id;
      this.itemChoice.name = this.item.name;
    },
    cancelEdit: function () {
      this.error = false;
      this.edit = false;
      if (this.item.name == '') {
        this.$emit('cancelAdd');
      }
      this.itemChoice.id = '';
      this.itemChoice.name = '';
    },
    updateItem: function () {
      this.edit = false;
      if (this.item.name == '') {
        this.item.name = this.itemChoice.name;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }
    },
    checkvalidation() {
      if (this.itemChoice.name) {
        this.error = false;
        this.updateItem();
      } else {
        this.error = true;
      }
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa thương hiệu!',
        text: 'Bạn có chắc muốn xóa thương hiệu ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.item.id);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkAddRow() {
      if (this.item.name == '') {
        this.edit = true;
      } else if (this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
  },
};
</script>
